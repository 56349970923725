import * as React from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';
import { appWithTranslation } from "next-i18next";
import Head from 'next/head';
import Script from 'next/script';
import 'styles/globals.scss'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { prefixer } from 'stylis';

import { useRouter } from 'next/router';
import themeLight from 'theme/themeLight';
import themeLightRTL from 'theme/themeLight-rtl';

import { motion, AnimatePresence } from "framer-motion";

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
});

const App = ({ Component, pageProps, router }) => {

  const uRouter = useRouter();
  const { locale } = uRouter;
  const [currentTheme, setCurrentTheme] = useState(locale!="en"?(locale!="en-SA"?themeLightRTL:themeLight):themeLight);

  useEffect(() => {
    setCurrentTheme(locale!="en"?(locale!="en-SA"?themeLightRTL:themeLight):themeLight);
  }, [locale]);

  return (
    <AnimatePresence initial={false} exitBeforeEnter onExitComplete={() => window.scrollTo(0, 0)}>
      <motion.div key={router.route} initial="pageInitial" animate="pageAnimate" exit="pageExit" 
        transition={{ ease: "easeInOut", duration: .4 }}
        variants={{
          pageInitial:{
            y: 20, opacity: 0
          },
          pageAnimate:{
            y: 0, opacity: 1
          },
          pageExit:{
            y: 0, opacity: 0
          }
        }}
      >
        <CacheProvider value={locale!="en"?(locale!="en-SA"?cacheRtl:cacheLtr):cacheLtr}>
          <ThemeProvider theme={currentTheme}>
            <div dir={locale!="en"?(locale!="en-SA"?"rtl":"ltr"):"ltr"}>
              <CssBaseline />
              
              
              <Head>
               {/* <!-- OneTrust Cookies Consent Notice start for jotunmeia.com --> */}
               <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="018dcb84-257c-7679-91cb-3f9cc62d3aee" />
                    <script
                      type="text/javascript"
                      dangerouslySetInnerHTML={{
                        __html: `function OptanonWrapper() { }`,
                      }}
                    />
                {/* <!-- OneTrust Cookies Consent Notice end for jotunmeia.com --> */}

              <script
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-W4N9J2L');`,
                }}
              />
            </Head>
            <noscript
    dangerouslySetInnerHTML={{
      __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W4N9J2L" height="0" width="0" style="display: none; visibility: hidden;" />`,
    }}
  />

              <Component {...pageProps }/>   
            </div>
          </ThemeProvider>
        </CacheProvider>
      </motion.div>
    </AnimatePresence>
  )
}

// export default withTheme(App);
export default appWithTranslation(App);
