import { createTheme } from '@mui/material/styles';

const themeLight = createTheme({
  direction: "rtl",
  breakpoints:{
    values:{
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1355,
      xl: 1680
    }
  },
  palette: {
    type: "light",
    background: {
      // paper: "#303030"
      // paper: "#212121",
      // default: "#212121",
    },
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E81F76",
      contrastText: "#fff",
    },
    whitecustom: {
      main: "#fff",
      contrastText: "#fff",
    },
    contrastThreshold:5,
  },
  typography: {
    fontFamily: 'inherit',
  },
  shape: {
    borderRadius: 0
  },
  components: { // custom components styling
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          
        },
        textPrimary:{
          textTransform:"none"
        },
        containedPrimary:{
          fontWeight: "700",
          textTransform:"uppercase"
        },
        containedSizeLarge:{
          padding:"0.875rem 1rem",
          fontSize: '1.125rem',
        }
      },
    },
    MuiTypography:{
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
        },
        h1:{
          fontWeight:"700"
        },
        h2:{
          fontWeight:"700"
        },
        h3:{
          fontWeight:"700"
        },
        h4:{
          fontWeight:"700"
        },
        h5:{
          fontWeight:"700"
        },
        h6:{
          fontWeight:"700"
        },
        body1:{
          fontSize:"1.125rem"
        }
      },
    },
    MuiCheckbox:{
      styleOverrides: {
        root:{

        },
        body1:{

        }
      }
    },
    MuiFormControlLabel:{
      styleOverrides: {
        root:{
          // margin:0
        },
      }
    }
  },
});

themeLight.props = {
  MuiButton: {
    disableElevation: true,
  },
};

themeLight.overrides = {
  MuiButton: {
    root: {
      borderRadius: 5,
      // textTransform: 'uppercase',
    },
    containedPrimary: {
      letterSpacing: '0',
      // '&:hover': {
      //   backgroundColor: theme.palette.secondary.main,
      //   color: theme.palette.primary.dark,
      // },
      '&:hover': {
        color: themeLight.palette.primary.contrastText,
      },
    },
    containedSecondary: {
      letterSpacing: '0',
      // fontWeight: 700,
      '&:hover': {
        color: themeLight.palette.secondary.contrastText,
      },
    },
    containedSizeLarge:{
      padding: '20px 50px'
    },
    containedSizeSmall:{
      padding: '8px 22px'
    },
    contained:{
      padding: '8px 40px'
    }
  },
  MuiTable: {
    root:{
      border:"1px solid #6F7271"
    }
  },
  MuiInputLabel: {
    root: {
      color:"#fff"
    },
  },
    
};

export default themeLight;